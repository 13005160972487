import React, {createRef, useCallback, useEffect, useMemo, useState} from 'react';
import {Divider, useTheme} from "@mui/material";
import {connect} from "react-redux";
import CustomSnackbar from "../alert/CustomSnackbar";
import DataGrid, {SelectColumn} from "react-data-grid";
import {
    CustomTableHeaderCellRender,
    EmptyRowsRenderer,
    GetListOfSelectedRowIDs,
    GetSelectedGroupByPlaceholderFromTrafficSource,
    GetSelectedRowID, HandleCopyNames,
    IsAtBottom
}
    from "../reporting-table/ReactDataGridUtils";
import {debounce} from "lodash";
import Spinner from "../spinner/Spinner";
import {
    DynamicGridColumnCreator,
    MarkColumn,
    StatusColumn
} from "../reporting-table/Columns";
import DetailPanelToolbar from "../reporting-table/DetailPanelToolbar";
import {groupBy2Action} from "../../store/actions/groupBy2Action";
import {alertActions} from "../../store/actions/alertActions";
import {columnActions} from "../../store/actions/columnAction";
import {workspaceActions} from "../../store/actions/workspaceAction";
import useCustomStyles from "../utils/UseCustomStyles";
import {CustomCampaignDataGridBox} from "./CampaignsView";

const customStyles = (theme) => ({
    dataGrid: theme.defaultDataGrid
});

function GroupBy2Grid(props) {
    const theme = useTheme();
    const classes = useCustomStyles(customStyles, theme);
    const {
        alert, loadDetailsReport, updateSortBy, updateOrderBy, updateSortColumns, setSelectedRows, refresh,
        updateStatus, updateBid, updateBudget, errorMsg, successMsg, columnGroup, report, getWorkspaceIDs
    } = props;
    const {integration, trafficSource} = props.groupBy1State;
    const {
        data, isLoading, orderBy, sortBy, sortColumns, rerender, groupBy, selectedRows, selectedUUIDs
    } = props.groupBy2State;
    const {from, to, isDeleted} = props.ddToolbarState;
    const {timezone, currencyCode} = props.auth.userSettings;

    let notAllowedMetricColumnsForPostbackParamReport = ["metric_uniqueVisits", "metric_uniqueClicks", "metric_botVisits", "metric_botClicks", "metric_visits", "metric_clicks", "metric_cpv", "metric_cpc", "metric_ctr", "metric_uctr", "metric_cr", "metric_cv", "metric_roi", "metric_epc", "metric_epv"];
    let allowedPostbackParamReportGroupByValues = ["postback_param1", "postback_param2", "postback_param3", "postback_param4", "postback_param5"];
    let notAllowedColumnsForPostbackParamReport = ["uniqueVisits", "uniqueClicks", "botVisits", "botClicks", "visits", "clicks", "cpv", "cpc", "ctr", "uctr", "cr", "cv", "roi", "epc", "epv"];


    const gridRef = createRef(null);
    const [columns, setColumns] = useState(null);

    const updateColumnResize = (idx, width) => {
        props.updateColumnWidth({columnKey: columns[idx]?.key, width, columnGroup, report});
    };

    const modifiedTableColumns = useMemo(() => {
        function CustomHeaderCell(props) {
            return <CustomTableHeaderCellRender {...props}/>;
        }
        return columns?.map((c) => {
            if (c?.key === "name") {
                c.name = groupBy?.label;
            }
            return {...c, renderHeaderCell: CustomHeaderCell};
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, columnGroup, report]);

    const getTableColumns = () => {
        let columns = [];
        props?.column?.data[columnGroup]?.forEach((col) => {
            if (allowedPostbackParamReportGroupByValues.includes(props?.groupBy1State?.groupBy?.groupBy) || allowedPostbackParamReportGroupByValues.includes(props?.groupBy2State?.groupBy?.groupBy)) {
                if (!col?.hidden && !notAllowedMetricColumnsForPostbackParamReport.includes(col?.reportColumnKey)) {
                    columns.push(col?.reportColumnKey);
                }
            } else {
                if (!col?.hidden) {
                    columns.push(col?.reportColumnKey);
                }
            }
        });
        return columns;
    };

    const loadMore = (pageNumber) => {
        let params = {
            groupBy: groupBy?.groupBy,
            from: from,
            to: to,
            timezone: timezone,
            orderBy: orderBy,
            sortBy: sortBy,
            page: pageNumber,
            pageSize: 100,
            currencyCode: currencyCode,
            "filters[table_campaign_ids]": GetListOfSelectedRowIDs(props?.campaignsState?.selectedRows),
            columns: getTableColumns(),
            workspace: getWorkspaceIDs()
        };

        params[`filters[${props.groupBy1State.groupBy?.groupBy}]`] = "";
        if (props.groupBy1State.selectedRows.size > 0) {
            for (const value of props.groupBy1State.selectedRows) {
                if (value !== "Unknown") {
                    params[`filters[${props.groupBy1State.groupBy?.groupBy}]`] = value;
                }
            }
        }

        // Add metric filters
        if (props?.metricFilter?.filters?.length > 0) {
            // eslint-disable-next-line array-callback-return
            props?.metricFilter?.filters?.map((obj) => {
                for (const metric in obj) {
                    params[`metricFilters[${metric}]`] = obj[metric];
                }
            });
        }

        loadDetailsReport(params, true);
    };

    const getDetailsReport = ({exportPage, exportPageSize, exportData}) => {

        gridRef?.current?.element?.scrollTo(0, 0);

        let params = {
            groupBy: groupBy?.groupBy,
            from: from,
            to: to,
            timezone: timezone,
            orderBy: orderBy,
            sortBy: sortBy,
            page: 1,
            pageSize: 100,
            currencyCode: currencyCode,
            "filters[table_campaign_ids]": GetListOfSelectedRowIDs(props?.campaignsState?.selectedRows),
            columns: getTableColumns(),
            workspace: getWorkspaceIDs()
        };

        params[`filters[${props.groupBy1State.groupBy?.groupBy}]`] = "";
        if (props.groupBy1State.selectedRows.size > 0) {
            for (const value of props.groupBy1State.selectedRows) {
                if (value !== "Unknown") {
                    params[`filters[${props.groupBy1State.groupBy?.groupBy}]`] = value;
                }
            }
        }

        if (exportData === "csv") {
            params.page = exportPage;
            params.pageSize = exportPageSize;
            params.export = exportData;
        }

        // Add metric filters
        if (props?.metricFilter?.filters?.length > 0) {
            // eslint-disable-next-line array-callback-return
            props?.metricFilter?.filters?.map((obj) => {
                for (const metric in obj) {
                    params[`metricFilters[${metric}]`] = obj[metric];
                }
            });
        }

        loadDetailsReport(params);
    };

    const handleInfinityScroll = (event) => {
        if (isLoading || !IsAtBottom(event)) {
            return;
        }

        let disable = data.page >= Math.ceil(data?.total?.totalRecords / data?.recordsPerPage);
        if (!disable) {
            loadMore(data.page + 1);
        }
    };

    const handleRefresh = () => {
        gridRef?.current?.element?.scrollTo(0, 0);
        refresh();
    };

    const handleExportCSV = () => {
        getDetailsReport({exportPage: 1, exportPageSize: 100000, exportData: "csv"});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleColumnResize = useCallback(debounce(updateColumnResize, 500), [columns, columnGroup, report]);

    const handleColumnsRender = (
        {
            addActionColumn = false,
            addBidColumn = false,
            addFbMetricCpmColumn = false,
            addFbMetricLinkCpcColumn = false,
            addFbMetricLinkCtrColumn = false,
            addFbMetricLinkClicksColumn = false,
            addFbMetricEpcColumn = false,
            addDeliveryStatusColumn = false,
            addFbImpressionsColumn = false,
            addFbDailyBudgetColumn = false,
            addOutbrainCpcAdjustmentColumn = false,
        }) => {
        let columns = [
            SelectColumn,
            MarkColumn,
            ...DynamicGridColumnCreator(
                {
                    columns: props?.column?.data[columnGroup],
                    addDeliveryStatusColumn,
                    addBidColumn,
                    addFbMetricCpmColumn,
                    addFbMetricLinkCpcColumn,
                    addFbMetricLinkCtrColumn,
                    addFbMetricLinkClicksColumn,
                    addFbMetricEpcColumn,
                    addFbImpressionsColumn,
                    addFbDailyBudgetColumn,
                    addOutbrainCpcAdjustmentColumn,
                    handleChangeDailyBudget: updateBudget,
                    handleOutbrainCpcAdjustment: updateBid,
                    handleChangeExternalBid: updateBid,
                }
            )
        ];
        // Add action columns.
        if (addActionColumn) {
            columns.splice(3, 0, StatusColumn(updateStatus));
        }

        if (allowedPostbackParamReportGroupByValues.includes(props?.groupBy1State?.groupBy?.groupBy) || allowedPostbackParamReportGroupByValues.includes(props?.groupBy2State?.groupBy?.groupBy)) {
            // eslint-disable-next-line array-callback-return
            let d = columns.filter((item) => {
                // Remove columns for Postback Param Report in Details view.
                if (!notAllowedColumnsForPostbackParamReport.includes(item?.key)) {
                    return item;
                }
            });
            setColumns(d);
        } else {
            setColumns(columns);
        }
    };

    const applyIntegrationActionColumns = () => {
        let listOfIntegrationMacros = [
            {networkType: "pushground", groupByPlaceholder: "{creativity_id}", changeStatus: true, changeBid: false},
            {networkType: "pushground", groupByPlaceholder: "{site_id}", changeStatus: true, changeBid: false},
            {networkType: "pushground", groupByPlaceholder: "{campaign_id}", changeStatus: true, changeBid: true},
            {networkType: "pushground", groupByPlaceholder: "{supply_id}", changeStatus: true, changeBid: true},
            {networkType: "propeller-ads", groupByPlaceholder: "{bannerid}", changeStatus: true, changeBid: false},
            {networkType: "propeller-ads", groupByPlaceholder: "{campaignid}", changeStatus: true, changeBid: true},
            {networkType: "propeller-ads", groupByPlaceholder: "{zoneid}", changeStatus: true, changeBid: true},
            {networkType: "exoclick", groupByPlaceholder: "{variation_id}", changeStatus: true, changeBid: false},
            {networkType: "exoclick", groupByPlaceholder: "{site_id}", changeStatus: true, changeBid: false},
            {networkType: "exoclick", groupByPlaceholder: "{campaign_id}", changeStatus: true, changeBid: true},
            {networkType: "exoclick", groupByPlaceholder: "{zone_id}", changeStatus: true, changeBid: true},
            {networkType: "mondiad", groupByPlaceholder: "[creativeid]", changeStatus: true, changeBid: false},
            {networkType: "mondiad", groupByPlaceholder: "[campaignid]", changeStatus: true, changeBid: true},
            {networkType: "mondiad", groupByPlaceholder: "[zoneid]", changeStatus: true, changeBid: true},
            {networkType: "mondiad", groupByPlaceholder: "[subid]", changeStatus: true, changeBid: true},
            {networkType: "google", groupByPlaceholder: "{campaignid}", changeStatus: true, changeBid: false},
            {networkType: "google", groupByPlaceholder: "{adgroupid}", changeStatus: true, changeBid: false},
            {networkType: "google", groupByPlaceholder: "{creative}", changeStatus: true, changeBid: false},
            {networkType: "microsoft", groupByPlaceholder: "{CampaignId}", changeStatus: true, changeBid: false},
            {networkType: "microsoft", groupByPlaceholder: "{AdGroupId}", changeStatus: true, changeBid: false},
            {networkType: "microsoft", groupByPlaceholder: "{AdId}", changeStatus: true, changeBid: false},
            {
                networkType: "facebook",
                groupByPlaceholder: "{{ad.id}}",
                changeStatus: true,
                dailyBudget: true,
                deliveryStatus: true,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            },
            {
                networkType: "facebook",
                groupByPlaceholder: "{{adset.id}}",
                changeStatus: true,
                dailyBudget: true,
                changeBid: true,
                deliveryStatus: true,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            },
            {
                networkType: "facebook",
                groupByPlaceholder: "{{campaign.id}}",
                changeStatus: true,
                dailyBudget: true,
                deliveryStatus: true,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            },
            {
                networkType: "facebook",
                breakdownByDay: "date",
                changeStatus: false,
                dailyBudget: false,
                deliveryStatus: false,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            },
            {
                networkType: "taboola",
                groupByPlaceholder: "{campaign_id}",
                changeStatus: true,
                changeBid: false,
                dailyBudget: true,
                deliveryStatus: true
            },
            {networkType: "taboola", groupByPlaceholder: "{campaign_item_id}", changeStatus: true, changeBid: false},
            {networkType: "taboola", groupByPlaceholder: "{site}", changeStatus: true, changeBid: true},
            {
                networkType: "mgid",
                groupByPlaceholder: "{campaign_id}",
                changeStatus: true,
                changeBid: false,
                deliveryStatus: true
            },
            {
                networkType: "mgid",
                groupByPlaceholder: "{teaser_id}",
                changeStatus: true,
                changeBid: true,
                deliveryStatus: true
            },
            {networkType: "mgid", groupByPlaceholder: "{widget_id}", changeStatus: true, changeBid: false},
            {
                networkType: "outbrain",
                groupByPlaceholder: "{{campaign_id}}",
                changeStatus: true,
                dailyBudget: true,
                changeBid: true,
                deliveryStatus: true
            },
            {
                networkType: "outbrain",
                groupByPlaceholder: "{{ad_id}}",
                changeStatus: true,
                changeBid: false,
                deliveryStatus: true
            },
            {
                networkType: "outbrain",
                groupByPlaceholder: "{{section_id}}",
                changeStatus: true,
                changeBid: false,
                deliveryStatus: true,
                adjustCpcPercentage: true
            },
            {
                networkType: "outbrain",
                groupByPlaceholder: "{{publisher_id}}",
                changeStatus: true,
                changeBid: false,
                deliveryStatus: true
            },
            {
                networkType: "tiktok",
                groupByPlaceholder: "__CAMPAIGN_ID__",
                changeStatus: true,
                dailyBudget: true,
                deliveryStatus: true,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            },
            {
                networkType: "tiktok",
                groupByPlaceholder: "__AID__",
                changeStatus: true,
                dailyBudget: true,
                deliveryStatus: true,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            },
            {
                networkType: "tiktok",
                groupByPlaceholder: "__CID__",
                changeStatus: true,
                dailyBudget: false,
                deliveryStatus: true,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            },
            {
                networkType: "tiktok",
                breakdownByDay: "date",
                changeStatus: false,
                dailyBudget: false,
                deliveryStatus: false,
                metricImpressions: true,
                metricCPM: true,
                metricLinkCPC: true,
                metricLinkCTR: true,
                metricLinkClicks: true,
                metricEpc: true,
            }
        ];

        let addDeliveryStatusColumn = false;
        let addFbDailyBudgetColumn = false;
        let addFbImpressionsColumn = false;
        let addFbMetricEpcColumn = false;
        let addFbMetricCpmColumn = false;
        let addFbMetricLinkCpcColumn = false;
        let addFbMetricLinkCtrColumn = false;
        let addFbMetricLinkClicksColumn = false;
        let addBidColumn = false;
        let addActionColumn = false;
        let addOutbrainCpcAdjustmentColumn = false;

        for (const int of listOfIntegrationMacros) {
            if (integration?.networkType === int?.networkType) {
                let groupByPlaceholder = GetSelectedGroupByPlaceholderFromTrafficSource(groupBy?.groupBy, trafficSource);
                if (groupByPlaceholder === int?.groupByPlaceholder || groupBy?.groupBy === int?.breakdownByDay) {
                    addDeliveryStatusColumn = int?.deliveryStatus;
                    addActionColumn = int?.changeStatus;
                    addBidColumn = int?.changeBid;
                    addFbDailyBudgetColumn = int?.dailyBudget;
                    addFbImpressionsColumn = int?.metricImpressions;
                    addFbMetricCpmColumn = int?.metricCPM;
                    addFbMetricLinkCpcColumn = int?.metricLinkCPC;
                    addFbMetricLinkCtrColumn = int?.metricLinkCTR;
                    addFbMetricLinkClicksColumn = int?.metricLinkClicks;
                    addFbMetricEpcColumn = int?.metricEpc;
                    addOutbrainCpcAdjustmentColumn = int?.adjustCpcPercentage;
                    break;
                }
            }
        }

        handleColumnsRender({
            addActionColumn,
            addBidColumn,
            addFbDailyBudgetColumn,
            addDeliveryStatusColumn,
            addFbImpressionsColumn,
            addFbMetricCpmColumn,
            addFbMetricLinkCpcColumn,
            addFbMetricLinkCtrColumn,
            addFbMetricLinkClicksColumn,
            addFbMetricEpcColumn,
            addOutbrainCpcAdjustmentColumn
        });
    };

    // Step 1: If integration and traffic source data has found.
    // Then match "Group by 2" value with traffic source placeholder and set columns.
    useEffect(() => {
        applyIntegrationActionColumns();
        getDetailsReport({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupBy, integration])

    // Step 2: Load details report if columns are set.
    useEffect(() => {
        if (columns !== null) {
            getDetailsReport({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from, to, timezone, isDeleted, sortBy, orderBy, currencyCode, props?.metricFilter?.filters]);

    // Only for rerender
    useEffect(() => {
        if (rerender && columns !== null) {
            getDetailsReport({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rerender]);

    // Rerender columns if width, visibility or sequence has changed.
    useEffect(() => {
        if (columns !== null) {
            applyIntegrationActionColumns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.column.data]);

    useEffect(() => {
        if (sortColumns.length === 0) {
            updateSortBy("");
            updateOrderBy("");
            return () => {
            };
        }
        const {columnKey, direction} = sortColumns[0];
        updateOrderBy(columnKey);
        updateSortBy(direction);
    }, [updateOrderBy, updateSortBy, sortColumns]);

    // 6. Rerender if user workspaces has changed.
    useEffect(() => {
        if (props?.workspace?.updated !== null) {
            getDetailsReport({});
            props?.resetUpdatedWorkspaceValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.workspace?.updated]);

    // 7. Force to rerender selected rows...
    useEffect(() => {
        if (selectedUUIDs?.size > 0) {
            let newUUIDs = new Set();
            for (const s of selectedRows) {
                data?.records?.forEach(i => {
                    if (i?.name === s) {
                        newUUIDs.add(i?.uuid)
                    }
                })
            }
            setSelectedRows(newUUIDs, selectedRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.groupBy2State?.data?.records]);

    // Using row ID as a value for these values.
    let a = ["campaign_id", "offer_id", "landing_id", "flow_id", "affiliate_network_id", "traffic_source_id"]

    return (
        <>
            <DetailPanelToolbar
                campaignId={GetSelectedRowID(props.campaignsState.selectedRows)}
                onClickRefresh={handleRefresh}
                onClickExportCSV={handleExportCSV}
                onClickCopyNames={() => HandleCopyNames({selectedRows, errorMsg, successMsg})}
                report={report}
                columns={columns}
                columnGroup={columnGroup}
            />
            <Divider/>
            <CustomCampaignDataGridBox>

                {alert.errorMsg !== "" && <CustomSnackbar message={alert.errorMsg} timeout={3000}/>}
                {alert.successMsg !== "" &&
                    <CustomSnackbar message={alert.successMsg} severity="success" timeout={3000}/>}

                {isLoading && <Spinner overComponentBox={true}/>}

                {columns !== null && classes !== null &&
                    <DataGrid
                        ref={gridRef}
                        rowKeyGetter={(row) => {
                            return row?.uuid;
                        }}
                        columns={modifiedTableColumns}
                        rows={data?.records ? data.records : []}
                        defaultColumnOptions={{
                            sortable: true,
                            resizable: true,
                            draggable: false
                        }}
                        onColumnResize={handleColumnResize}
                        sortColumns={sortColumns}
                        onSortColumnsChange={updateSortColumns}
                        selectedRows={selectedUUIDs}
                        onSelectedRowsChange={(uuids) => {
                            let realIds = new Set();
                            for (const s of uuids) {
                                data?.records?.forEach(i => {
                                    if (i?.uuid === s) {
                                        if (i?.id > 0 && a.includes(props?.groupBy2State?.groupBy?.groupBy)) {
                                            realIds.add(i?.id);
                                        } else {
                                            realIds.add(i?.name);
                                        }
                                    }
                                })
                            }
                            setSelectedRows(uuids, realIds);
                        }}
                        onCellClick={(args) => {
                            // disable row select for these columns.
                            if (args?.column?.key === "status" || args?.column?.key === "external_bid" || args?.column?.key === "fb_daily_budget") {
                                return false;
                            }

                            if (args?.column?.key !== "select-row") {
                                if (props.groupBy2State.selectedRows?.size > 0) {
                                    props.groupBy2State.selectedUUIDs.clear();
                                    props.groupBy2State.selectedRows.clear();
                                }

                                if (props.groupBy3State.selectedRows?.size > 0) {
                                    props.groupBy3State.selectedUUIDs.clear();
                                    props.groupBy3State.selectedRows.clear();
                                }

                                if (args?.row?.id > 0 && a.includes(props?.groupBy2State?.groupBy?.groupBy)) {
                                    setSelectedRows(new Set().add(args?.row?.uuid), new Set().add(args?.row?.id));
                                } else {
                                    setSelectedRows(new Set().add(args?.row?.uuid), new Set().add(args?.row?.name));
                                }
                            }
                        }}
                        onSelectedCellChange={({rowIdx, row, column}) => {

                            // disable row select for these columns.
                            if (column?.key === "status" || column?.key === "external_bid") {
                                return false;
                            }

                            if (rowIdx !== -1 && column?.key !== "select-row") {
                                if (props.groupBy2State.selectedRows?.size > 0) {
                                    props.groupBy2State.selectedUUIDs.clear();
                                    props.groupBy2State.selectedRows.clear();
                                }

                                if (props.groupBy3State.selectedRows?.size > 0) {
                                    props.groupBy3State.selectedUUIDs.clear();
                                    props.groupBy3State.selectedRows.clear();
                                }

                                if (row?.id > 0 && a.includes(props?.groupBy2State?.groupBy?.groupBy)) {
                                    setSelectedRows(new Set().add(row?.uuid), new Set().add(row?.id));
                                } else {
                                    setSelectedRows(new Set().add(row?.uuid), new Set().add(row?.name));
                                }
                            }
                        }}
                        onCellDoubleClick={({row, column}) => {
                            if (column?.key === "status" || column?.key === "external_bid" || column?.key === "fb_daily_budget") {
                                return false;
                            }
                            props.handleDoubleClick(row, column);
                        }}
                        onScroll={handleInfinityScroll}
                        noRowsFallback={<EmptyRowsRenderer/>}
                        summaryRowHeight={35}
                        bottomSummaryRows={[data]}
                        enableVirtualization={true}
                        className={classes?.dataGrid}
                    />
                }
            </CustomCampaignDataGridBox>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        campaignsState: state.campaign,
        groupBy1State: state.groupBy1,
        groupBy2State: state.groupBy2,
        ddToolbarState: state.ddToolbar,
        auth: state.auth,
        alert: state.alert,
        metricFilter: state.metricFilter,
        column: state.column,
        workspace: state.workspace
    }
};

const actionCreators = {
    loadDetailsReport: groupBy2Action.getDetailsReport,
    updateOrderBy: groupBy2Action.updateOrderBy,
    updateSortBy: groupBy2Action.updateSortBy,
    updateSortColumns: groupBy2Action.updateSortColumns,
    setSelectedRows: groupBy2Action.setSelectedRows,
    updateGroupBy2: groupBy2Action.updateGroupBy,
    refresh: groupBy2Action.rerender,
    updateStatus: groupBy2Action.updateStatus,
    updateBid: groupBy2Action.updateBid,
    updateBudget: groupBy2Action.updateBudget,
    errorMsg: alertActions.error,
    successMsg: alertActions.success,
    updateColumnWidth: columnActions.updateColumnWidth,
    getWorkspaceIDs: workspaceActions.getWorkspaceIDs,
    resetUpdatedWorkspaceValue: workspaceActions.resetUpdatedWorkspaceValue
};

export default connect(mapStateToProps, actionCreators)(GroupBy2Grid);